import {Category, Place, Section, SeatingReservationSummary} from '@wix/ambassador-seating-v1-seating-plan/types'
import {createAction} from '@reduxjs/toolkit'
import {getEventId, WIX_EVENTS_TICKET_DEFINITION_FQDN, PlaceWithTicketInfo} from '@wix/wix-events-commons-statics'
import {TFunction} from '@wix/yoshi-flow-editor'
import {getFee, getTax} from '../selectors/tax-and-fee'
import {DONATION_ERROR, SeatingState} from '../types'
import {isChooseSeatMode} from '../selectors/navigation'
import {createAsyncAction} from '../services/redux-toolkit'
import {getTicketPriceText} from '../selectors/order-success'

export const describeSeatingPlan = createAsyncAction<Partial<SeatingState>, void>(
  'DESCRIBE_SEATING_PLAN',
  async (_, {getState, extra: {serverApi, flowAPI}}) => {
    const t: TFunction = flowAPI.translations.t as TFunction
    const {tickets, event} = getState()
    const response = await serverApi.describeSeatingPlan(getEventId(event))
    const {
      plan,
      plan: {sections},
      seatingReservationSummary,
    } = response
    const categories = response.plan.categories.filter(category => category.totalCapacity)
    const uncategorizedPlaces = response.plan.uncategorizedPlaces
      .filter(place => place.capacity)
      .map(place =>
        getPlaceWithTicketInfo({place, category: null, sections, tickets, event, t, seatingReservationSummary}),
      )

    const placesWithInfo: PlaceWithTicketInfo[] = [
      ...categories.reduce((places, category) => {
        const {single, whole} = category.places.reduce(
          (acc, place) => {
            if (!place.capacity) {
              return acc
            }

            const [sectorId, elementId] = place.id.split('-')
            const section = sections.find(item => item.id === Number(sectorId))
            const element = section.elements.find(item => item.id === Number(elementId))
            const placeWithTicketInfo = getPlaceWithTicketInfo({
              place,
              category,
              sections,
              tickets,
              event,
              t,
              seatingReservationSummary,
            })

            if (element.reservationOptions?.reserveWholeElement) {
              const added = acc.whole.find(
                wholeResElement =>
                  `${wholeResElement.sectorId}-${wholeResElement.elementId}` ===
                  `${placeWithTicketInfo.sectorId}-${placeWithTicketInfo.elementId}`,
              )

              added
                ? added.places.push(placeWithTicketInfo)
                : acc.whole.push({...placeWithTicketInfo, places: [placeWithTicketInfo]})
            } else {
              acc.single.push(placeWithTicketInfo)
            }

            return acc
          },
          {single: [], whole: []} as {single: PlaceWithTicketInfo[]; whole: PlaceWithTicketInfo[]},
        )

        return [...places, ...single, ...whole]
      }, [] as PlaceWithTicketInfo[]),
      ...uncategorizedPlaces,
    ]

    return {
      plan,
      places: placesWithInfo,
    }
  },
)

export const selectPrice = createAction<string>('SELECT_PRICE')

interface AddPlaceToBasketArgs {
  placeId: string
  count: number
  origin: string
}

export const addPlaceToBasket = createAsyncAction<{places: PlaceWithTicketInfo[]}, AddPlaceToBasketArgs>(
  'ADD_PLACE_TO_BASKET',
  async ({placeId, count}, {getState}) => {
    const state = getState()
    const chooseSeatMode = isChooseSeatMode(state)

    return {
      places: state.seating.places.map(place => {
        if (place.id === placeId) {
          return {
            ...place,
            quantity: count,
            inBasket: Boolean(count),
            selected: false,
            donation: count >= 1 ? place.donation : undefined,
            timeAddedToBasket: !place.quantity && count ? new Date().getTime() : place.timeAddedToBasket,
          }
        } else {
          return chooseSeatMode ? {...place, quantity: 0, inBasket: false, selected: false} : place
        }
      }),
    }
  },
)

interface SelectPlaceArgs {
  placeId: string
  mobile: boolean
}

export const selectPlace = createAsyncAction<{places: PlaceWithTicketInfo[]}, SelectPlaceArgs>(
  'SELECT_PLACE',
  async ({placeId, mobile}, {getState}) => {
    const state = getState()

    return {
      places: state.seating.places.map(place => {
        if (place.id === placeId) {
          return {
            ...place,
            selected: true,
          }
        } else {
          return mobile ? {...place, selected: false} : place
        }
      }),
    }
  },
)

export const unselectPlace = createAsyncAction<{places: PlaceWithTicketInfo[]}, {placeId: string}>(
  'UNSELECT_PLACE',
  async ({placeId}, {getState}) => {
    const state = getState()

    return {
      places: state.seating.places.map(place =>
        place.id === placeId
          ? {
              ...place,
              selected: false,
            }
          : place,
      ),
    }
  },
)

export const addPlaceDonation = createAsyncAction<{places: PlaceWithTicketInfo[]}, {placeId: string; donation: string}>(
  'ADD_PLACE_DONATION',
  async ({placeId, donation}, {getState}) => {
    const state = getState()

    return {
      places: state.seating.places.map(place => {
        if (place.id === placeId) {
          return {
            ...place,
            donation,
          }
        } else {
          return place
        }
      }),
    }
  },
)

export const setPlaceDonationError = createAction<{placeId: string; error: DONATION_ERROR}>('SET_PLACE_DONATION_ERROR')

export const selectLocation = createAction<string>('SELECT_LOCATION')

export const resetFilters = createAsyncAction('RESET_FILTERS', async (_, {dispatch}) => {
  await dispatch(selectPrice(null))
  await dispatch(selectLocation(null))
})

export const setShowAccessibilityMode = createAction<boolean>('SET_SHOW_ACCESSIBILITY_MODE')

const getPlaceWithTicketInfo = ({
  place: {id, label, capacity, elementType},
  category,
  sections,
  tickets,
  event,
  t,
  seatingReservationSummary,
}: GetPlaceWithTicketInfoParams): PlaceWithTicketInfo => {
  const {
    externalId,
    config: {color},
  } = category ?? {config: {}}
  const ticketDefinition = tickets.find(
    ticketDef => ticketDef.id === externalId?.replace(WIX_EVENTS_TICKET_DEFINITION_FQDN, ''),
  )
  const [sectorId, elementId] = id.split('-')
  const section = sections.find(item => item.id === Number(sectorId))
  const element = section.elements.find(item => item.id === Number(elementId))

  return {
    id,
    elementType,
    label,
    color,
    capacity: capacity - seatingReservationSummary.places.find(({placeId}) => placeId === id).occupied,
    quantity: 0,
    selected: false,
    inBasket: false,
    sectorId,
    sector: sectorId === '0' ? undefined : section.title,
    elementLabel: element.title,
    elementId: element.id,
    ticket: ticketDefinition,
    ticketPrice: ticketDefinition ? getTicketPriceText(ticketDefinition.price, t) : null,
    donation: undefined,
    fees: ticketDefinition
      ? [getTax(event, ticketDefinition, t), getFee(event, ticketDefinition, t)].filter(Boolean).join(', ')
      : null,
    reservationOptions: element.reservationOptions,
  }
}

export enum SEATING_ERROR {
  TICKET_LIMIT_REACHED = 'TICKET_LIMIT_REACHED',
}

export const setSeatingError = createAction<SEATING_ERROR>('SET_SEATING_ERROR')

interface GetPlaceWithTicketInfoParams {
  place: Place
  category: Category
  sections: Section[]
  tickets: wix.events.ticketing.TicketDefinition[]
  event: wix.events.Event
  t: TFunction
  seatingReservationSummary: SeatingReservationSummary
}

export const seatingMapButtonClick = createAction<string>('SEATING_MAP_BUTTON_CLICK')
